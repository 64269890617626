import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import {
  FlashyCustomDataService,
  FlashyPaginationService,
} from '@flashy/store';

export class FlashyDataService<T> extends DataSource<T> {
  loadingData$ = new BehaviorSubject(false);
  private _subscription = new Subscription();
  private _pageSize = 50;
  private _pageCache = new Set<number>();
  private _results$ = new BehaviorSubject<T[]>([]);

  constructor(
    private nextPageCb$: (page: number) => Observable<T[]>,
    private customDataService?:
      | FlashyCustomDataService<T>
      | FlashyPaginationService<T>,
    private onDestroy?: () => void
  ) {
    super();
  }

  get results$(): BehaviorSubject<T[]> | Observable<T[]> {
    if (this.customDataService instanceof FlashyPaginationService) {
      return this.customDataService.filteredEntities$;
    }
    return this.customDataService?.entities$ || this._results$;
  }

  get count(): number {
    return (
      this.customDataService?.count$.getValue() ||
      this._results$.getValue().length
    );
  }

  get count$(): Observable<number> {
    return (
      this.customDataService?.count$ ||
      this._results$.pipe(map((results) => results.length))
    );
  }

  stopListeningToSubscription(): void {
    if (!this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    this.getNewPage(1); // Loads first page
    this._subscription.add(
      collectionViewer.viewChange.subscribe(({ end }) => {
        // Add 10 rows to trigger loading before list ends
        this.getNewPage(this._getPageForIndex(end + 10));
      })
    );
    return this.results$;
  }

  disconnect(): void {
    this.stopListeningToSubscription();
    this.onDestroy?.();
    if (this.customDataService instanceof FlashyPaginationService) {
      this.customDataService.setEntities([]);
    }
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize) + 1; // Start page numbers from 1
  }

  private getNewPage(page: number): void {
    if (!this._pageCache.has(page)) {
      this._pageCache.add(page);
      this.loadingData$.next(true);
      this.nextPageCb$(page)
        .pipe(
          take(1),
          catchError(() => {
            // console.log(err);
            this._pageCache.delete(page);
            this.loadingData$.next(false);
            this.stopListeningToSubscription(); // Stop sending many requests, as this causing loops
            return EMPTY;
          })
        )
        .subscribe((entities) => {
          if (!this.customDataService && entities?.length) {
            const currentEntities = this._results$.getValue();
            this._results$.next([...currentEntities, ...entities]);
          }
          this.loadingData$.next(false);
          /**
           * Stop sending the requests if the previuos entities are less than pageSize
           * Avoid sending empty requests
           */
          if (entities?.length < this._pageSize) {
            this.stopListeningToSubscription();
          }
        });
    }
  }
}
